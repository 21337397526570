/*
 * It is advised not to edit the smartadmin_production.css
 * as this will override any changes you make in the 
 * later versions of this theme. 
 * 
 * We advise that you use use this CSS to override 
 * SmartAdmin styles. 
 * 
 * Rename the stylesheet to whatever your liking so it will stay
 * unique to you with each update of SmartAdmin.
 */
.jarviswidget .widget-body {
  min-height: 10px;
}
#loading {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
  display: block;
  opacity: 0.4;
  background-color: #fff;
  z-index: 99;
  text-align: center;
}
#loading img {
  position: absolute;
  top: calc(50% - 50px);
  left: 50%;
  z-index: 100;
}
.info-panel {
  padding: 150px 20px;
  font-size: 2em;
  font-weight: lighter;
}
.profile-context {
  display: inline-block;
  padding: 7px 13px 0;
  position: relative;
  width: 100%;
}
.profile-context > span {
  display: block;
}
.profile-context > :first-child {
  display: block;
  color: #bfbfbf;
  font-weight: 700;
  padding-left: 0;
  text-align: left;
}
.profile-selector {
  max-width: 97%;
  background: 0 0;
  border: none;
  padding: 0;
  text-align: left;
  color: #555;
  font-size: 14px;
  cursor: pointer;
}
.profile-selector:hover {
  color: #fa9c08;
  font-weight: bold;
}
.vault-item {
  border: 1px solid #ccc;
  padding: 3px;
  margin: 3px;
  background-color: #eee;
  width: 100%;
  border-radius: 4px;
}
#extr-page #header {
  background: #58585a !important;
  color: white;
}
#extr-page #header #logo {
  margin-top: 12px;
}
#header {
  background: #58585a !important;
  color: white;
}
#header #logo {
  margin-top: 6px;
}
.padding-top-0 {
  padding-top: 0 !important;
}
.padding-top-5 {
  padding-top: 5px !important;
}
.padding-top-10 {
  padding-top: 10px !important;
}
.padding-top-15 {
  padding-top: 15px !important;
}
.padding-top-20 {
  padding-top: 20px !important;
}
.padding-bottom-0 {
  padding-bottom: 0 !important;
}
.padding-bottom-5 {
  padding-bottom: 5px !important;
}
.padding-bottom-10 {
  padding-bottom: 10px !important;
}
.padding-bottom-15 {
  padding-bottom: 15px !important;
}
.padding-bottom-20 {
  padding-bottom: 20px !important;
}
.padding-left-0 {
  padding-left: 0 !important;
}
.padding-left-5 {
  padding-left: 5px !important;
}
.padding-left-10 {
  padding-left: 10px !important;
}
.padding-left-15 {
  padding-left: 15px !important;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.padding-right-0 {
  padding-right: 0 !important;
}
.padding-right-5 {
  padding-right: 5px !important;
}
.padding-right-10 {
  padding-right: 10px !important;
}
.padding-right-15 {
  padding-right: 15px !important;
}
.padding-right-20 {
  padding-right: 20px !important;
}
.margin-top-0 {
  margin-top: 0 !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-top-20 {
  margin-top: 20px !important;
}
.margin-bottom-0 {
  margin-bottom: 0 !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.margin-left-5 {
  margin-left: 5px !important;
}
.margin-left-10 {
  margin-left: 10px !important;
}
.margin-right-5 {
  margin-right: 5px !important;
}
.margin-right-10 {
  margin-right: 10px !important;
}
.margin-right-15 {
  margin-right: 15px !important;
}
/* SmartForm Bootstrap Validation */
#extr-page h4.paragraph-header {
  width: auto;
}
#extr-page .smart-form .alert {
  margin: 4px;
}
#extr-page .smart-form .alert li {
  margin-left: 14px;
  list-style: none;
}
#extr-page .smart-form .alert li:before {
  content: "\f071";
  font-family: FontAwesome;
  position: absolute;
  left: 14px;
}
#extr-page .smart-form div + fieldset {
  padding-top: 15px;
}
.menu-item-parent {
  width: 150px;
  max-width: 180px !important;
}
.vault-file {
  display: block;
  margin: 5px 0;
  padding: 5px;
  border: 1px dotted #ddd;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  position: relative;
}
.vault-file b {
  font-style: italic;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.vault-file.active {
  background-color: #e5e5e5;
}
.pdf-container .pdf {
  width: 100%;
  background-color: white;
}
.files-container td {
  position: relative;
}
.files-container .meta-data label {
  text-transform: uppercase;
  font-size: 0.8em;
  margin-bottom: 0;
  margin-top: 8px;
}
.files-container .meta-data .value {
  font-weight: bold;
}
.title-panel {
  padding-top: 0;
  padding-bottom: 10px;
}
.title-panel .secondary {
  font-size: 25px;
  margin-top: 0;
  margin-bottom: 0;
}
.title-panel .primary {
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 0;
}
.title-panel .tertiary {
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 0;
}
.title-panel label {
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0;
}
.modal-body .dropzone {
  min-height: inherit;
}
body.modal-open .datepicker {
  z-index: 1200 !important;
}
.project-context h1 {
  margin: 0;
}
.invaulta-header {
  padding: 5px;
  background-color: #58585a;
  color: #daa520;
}
.invaulta-header h2 {
  font-size: 14px;
  font-weight: lighter;
  text-transform: uppercase;
  position: absolute;
  top: 15px;
  left: 270px;
}
.invaulta-panel {
  margin-top: 20px;
  text-align: center;
}
.invaulta-panel .by-line {
  font-family: "open sans";
  font-size: 36px;
}
.invaulta-panel .by-line-2 {
  color: #696969;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}
.invaulta-panel .request-line {
  text-align: center;
}
.invaulta-info {
  margin-top: 40px;
  text-align: center;
  background-color: #696969;
  color: #babfbf;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 5px;
}
.invaulta-info .t1 {
  color: #daa520;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid cyan 1px;
}
.select2-container--default .select2-selection--multiple {
  border-radius: 0 !important;
}
.jarviswidget > header h2 {
  position: absolute !important;
}
